import React from "react";

const Faq = () => {
  return (
    <section className="py-3">
      <div className="container px-3 my-4">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            {/* FAQ Accordion 1*/}
            <h2 className="fw-bolder mb-5">Frequently Asked Questions</h2>
            <div className="accordion mb-5" id="accordionExample">
              <>
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      What is Dr.ChatGpt?
                    </button>
                  </h3>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Dr.ChatGpt is an AI-powered chatbot that provides medical
                      advice and answers to health-related queries. Also
                      Dr.ChatGpt is not a medical professional and should not be
                      used as a substitute for professional medical advice. The
                      information provided by Dr.ChatGpt is purely informational
                      and should not be considered equivalent to the advice of a
                      licensed physician. The website owner and developers are
                      not responsible for any consequences that may result from
                      following the advice of the AI chatbot.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Can I use Dr.ChatGpt in my preferred language?
                    </button>
                  </h3>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      We understand that language barriers can make accessing
                      medical advice difficult, which is why we're proud to
                      offer Dr.ChatGpt in over 100 languages. You can select
                      your preferred language from the language dropdown menu
                      before starting your chat, and Dr.ChatGpt will respond to
                      your queries in your preferred language.
                      <p></p>
                      When you select your preferred language, you still can ask
                      your questions in English or your preffered language. Dr.
                      ChatGpt will understand your questions in both languages
                      and will respond in your preferred language.
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header" id="heading10">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse10"
                      aria-expanded="false"
                      aria-controls="collapse10"
                    >
                      How can I ask/frame questions better to Dr.ChatGpt?
                    </button>
                  </h3>
                  <div
                    id="collapse10"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading10"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Ask questions more naturally like how you ask to your
                      doctor. Do not worry about grammatically correct
                      sentences. Our AI will understand your questions.
                      <p></p>
                      Keep your question elaborate and specific.
                      <p></p>
                      <strong>Example:</strong>
                      <p></p>
                      1. I have a headache and I am feeling dizzy. What should I
                      do?
                      <p></p>
                      2. I am 35 years old. I feel shoulder pain each day at end
                      of the day. I am not able to sleep properly. I am feeling
                      very tired. What should I do?
                      <p></p>
                      3. Can you suggest some home remedies for cough?
                      <p></p>
                      Also remember that Dr.ChatGpt knows the context of your
                      previous questions. So, you can ask follow-up questions
                      without repeating the previous questions.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Is Dr.ChatGpt's medical advice equivalent to that of a
                      professional doctor?
                    </button>
                  </h3>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Please note that while Dr.ChatGpt is a valuable resource
                      for medical information, the advice provided by the
                      chatbot should never be considered equivalent to that of a
                      professional doctor. It is purely informational and should
                      be used as a supplement to, not a replacement for, medical
                      advice from a licensed physician. <p></p>
                      As with any medical information, we strongly advise users
                      to exercise caution and use their own knowledge and
                      judgment when using Dr.ChatGpt. It is important to consult
                      with a qualified healthcare provider for any medical
                      concerns or questions. We want our users to prioritize
                      their own health and safety, and to use Dr.ChatGpt as a
                      helpful tool in seeking the medical advice they need.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Can I use Dr.ChatGpt for non-medical conversations?
                    </button>
                  </h3>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      No, the website only supports medical-related
                      conversations.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed"
                      ß
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      Does the website store any user information or
                      conversation history?
                    </button>
                  </h3>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Dr.ChatGpt is committed to protecting the privacy of our
                      users. We do not store any user information or
                      conversation history on our backend, and we do not share
                      any user information with third parties.
                    </div>
                  </div>
                </div>

                <>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        Is the website completely free to use?
                      </button>
                    </h3>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        We believe that everyone should have access to reliable
                        medical advice and information, regardless of their
                        location or language. That's why we're proud to offer
                        our AI-powered medical assistant, Dr.ChatGpt, completely
                        free of charge. <p></p> Although we do incur costs for
                        the various backend API services that power our
                        platform, we are committed to making our service
                        accessible to all. To help us continue providing this
                        valuable resource, we welcome sponsorships from
                        organizations that share our mission. Please visit our
                        Contact page to get in touch with us, or consider making
                        a donation on our Donate page. We appreciate your
                        support!
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        Is the website operational in full mode?
                      </button>
                    </h3>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        No, the website is currently in beta and experimental
                        mode.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        Can I authenticate and login to the website as a user?
                      </button>
                    </h3>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        No, authentication and login are not possible at this
                        time as the website is in beta mode.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingNine">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        How does Dr.ChatGpt provide answers to my questions?
                      </button>
                    </h3>
                    <div
                      id="collapseNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingNine"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        All user requests are passed to ChatGpt in the backend,
                        and the response is shown to the user without any
                        modifications.
                      </div>
                    </div>
                  </div>
                </>
              </>
            </div>
            {/* FAQ Accordion 2*/}
          </div>
        </div>
        <div style={{ height: 170 }} />
      </div>
    </section>
  );
};

export default Faq;
