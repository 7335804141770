import React from 'react'

import Hero from '../components/landing/Hero'

import Quote from '../components/landing/Quote'  

const Home = () => {
  return (
    <div>
      <Hero />       
      <Quote />
    </div>     

  )
}

export default Home