import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import Donate from "./pages/Donate";
import Chat from "./pages/Chat";
import Login from "./pages/Login";
import Terms from "./pages/Terms";

import ChatRoute from "./components/routes/ChatRoute";


import Layout from "./components/layout/Layout";

function App() {
  return (
    <Layout>     
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/login" element={<Login />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/chat/:language" element={<Chat />} />
      </Routes>
    </Layout>
  );
}

export default App;
