import React from 'react'

const ChatMessageUser = ({ message }) => {

  return (
    <div className="d-flex flex-row justify-content-end mb-4 mt-4 ps-5 pt-1">
    <div>
      <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
      {message.content}
      </p>      
      {/* <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">
        00:00
      </p> */}
    </div>

    
     <img
      src="/newuser.png"
      alt="avatar 1"
      style={{ width: 45, height: "100%" }}
    /> 
  </div>
  )
}

export default ChatMessageUser