import React from "react";

import ChatHeader from "./ChatHeader";
import ChatBody from "./ChatBody";
import ChatFooter from "./ChatFooter";

import { useState, useEffect } from "react";

const ChatBox = (props) => {
  const { language } = props;

  console.log(language);

  const [currentModel, setCurrentModel] = useState("text-davinci-003");

  let greetmsg =
    language === "en"
      ? "Hi, I'm Dr.ChatGpt. Do you have any question on health?"      
      : "";


      let temp =
      language === "en"
        ? "Hi, I'm Dr.ChatGpt. I am currently unavailable to service your request. Will be online soon. Please check this page again."      
        : "";
  

  const [greetingMsg, setgreetingMsg] = useState(greetmsg);
  const [promptMsg, setpromptMsg] = useState("Enter your question..");
  const [chatLog, setChatLog] = useState([
     {
      role: "assistant",
      content: greetingMsg,
    }, 
  ]);

  function onClearBtnClick() {
    setChatLog([
      {
        role: "assistant",
        content: greetingMsg,
      },
    ]);
  }

  useEffect(() => {
    //If language is english, then no need to translate
    if (language !== "en") {

      fetch("https://drchatgpt-be-cqhutus52q-uc.a.run.app/translate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: "Hi, I'm Dr.ChatGpt. Do you have any question on health?",
          fromlang: "en",
          tolang: language,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setgreetingMsg(data.destmessage);
          console.log(data.destmessage)
          setChatLog([           
            { role: "assistant", content: `${data.destmessage}` },
          ]);
        });


        fetch("https://drchatgpt-be-cqhutus52q-uc.a.run.app/translate", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: "Type your question here..",
            fromlang: "en",
            tolang: language,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {            
            setpromptMsg(data.destmessage);
          });


    }
  }, []);

  const onAddChatInput = async (chatInput) => {
   
    let chatLogNew = [...chatLog, { role: "user", content: `${chatInput}` }];

    setChatLog(chatLogNew);

    const chatLogForServerWithoutFirst = chatLogNew.slice(1);

    // Make a deep copy of  chatLogForServerWithoutFirst
    const chatLogForServerWithoutFirstCopy = JSON.parse(JSON.stringify(chatLogForServerWithoutFirst));

    // Add typing indicator
    //chatLogNew = [...chatLogNew, { user: "gpt", message: "..." }];
    //setChatLog(chatLogNew);

    //Loop through the chatlog and get the english content if it is present for the message with role as assistant and
    //set the content to the english content and remove the english content from the message
    const finalchatlog  = chatLogForServerWithoutFirstCopy.map((message) => {
      if (message.role === "assistant" && message.engcontent !== undefined) {
        message.content = message.engcontent;
        delete message.engcontent;
      }
      return message;
    }
    );
 
    //const response = await fetch("https://chatgpt-startkit-cqhutus52q-uc.a.run.app", {
    const response = await fetch(
      "https://drchatgpt-be-cqhutus52q-uc.a.run.app/drchatgpt3",
      //"http://localhost:3000/drchatgpt3",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userchats: finalchatlog,
          lang: language,
          model: currentModel,
          fromwhere: "chatbox",
        }),
      }
    );

    // get the response from the api and set it to the chat log
    const data = await response.json();
         
     setChatLog([
      ...chatLogNew,
      { role: "assistant", content: `${data.destmessage}`, engcontent: `${data.engmessage}` },
    ]); 
    var scrollToTheBottomChatLog =
      document.getElementsByClassName("card-body")[0];
    scrollToTheBottomChatLog.scrollTop = scrollToTheBottomChatLog.scrollHeight;
  };

  return (
    <section style={{ backgroundColor: "#eee" }}>
      <div className="container py-3">
        <div className="row d-flex justify-content-center">
          <div>
            <div className="card" id="chat2">
              <ChatHeader onClearBtnClick={onClearBtnClick} />
              <ChatBody chatLog={chatLog} />
              <ChatFooter onAddChatInput={onAddChatInput} promptMsg={promptMsg} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChatBox;
