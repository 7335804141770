import React from "react";

const Donate = () => {
  return (
    <>
      <header >
      <div className="container px-3 mb-5" 
      >
          <div className="row justify-content-center">
            <div className="col-lg-8 ">
              <div className="my-5">
                <h4 className="mb-3">Dear Readers,</h4>
                Thank you for considering a donation to Dr.ChatGpt. We are
                committed to providing a valuable resource for medical
                information and advice to people all over the world, and we rely
                on the generosity of our users to help us cover the costs of our
                backend services that includes  Google Translation APIs and Openapi GPT-3 APIs service cost.
                <p></p>
                If you have found Dr.ChatGpt to be a helpful resource for
                medical information, we encourage you to make a donation to help
                us continue providing this valuable service. Your donation, no
                matter the size, will make a significant difference in our
                ability to operate and expand our services.
                <p></p>
                <p></p>
                To make a donation, simply click on the "Donate" button below
                and follow the instructions to make a payment through PayPal.
                PayPal is a safe and secure payment platform that allows you to
                donate using a credit card or bank account. We are grateful for
                any amount you are able to give, and we thank you for your
                support.
                <p></p>

<br />

                <form
                  action="https://www.paypal.com/donate"
                  method="post"
                  target="_top"
                >
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    defaultValue="NXK3J43R5UQ5W"
                  />
                  <input
                    type="image"
                    src="https://www.paypalobjects.com/en_US/CH/i/btn/btn_donateCC_LG.gif"
                    border={0}
                    name="submit"
                    title="PayPal - The safer, easier way to pay online!"
                    alt="Donate with PayPal button"
                  />
                  <img
                    alt=""
                    border={0}
                    src="https://www.paypal.com/en_CH/i/scr/pixel.gif"
                    width={1}
                    height={1}
                  />
                </form>
                <p></p>
                <br />
                <strong>Antony Johnson Cruz </strong> <br />
                  
                Founder, www.drchatgpt.org
                <p></p>
               
                {/* <div className="text-left">
                  <img
                    className="img-fluid square mb-4 px-4 w-25"
                    src="https://media.licdn.com/dms/image/C4E03AQHX7qEOL3ypIA/profile-displayphoto-shrink_800_800/0/1521932995835?e=1681344000&v=beta&t=Md7KhpNDW4qhND1kUuMlMki6wbL73OrjbtNkw8J9eFE"
                    alt="..."
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: 220}} />
      </header>
      {/* Team members section*/}
    </>
  );
};

export default Donate;
