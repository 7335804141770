import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (


    <header className="bg-dark py-2">
      <div className="container px-5">
        <div className="row gx-5 align-items-center justify-content-center">
          <div className="col-lg-8 col-xl-7 col-xxl-6">
            <div className="my-5 text-center text-xl-start">
              <h1 className="display-5 fw-bolder text-white mb-2">
                Meet our AI Medical Assistant
              </h1>
              <p className="lead fw-normal text-white-50 mb-4">
                Your Personal AI Medical Chatbot app in Over 100 Languages!
                <br />
                Get instant answers to your symptoms, diagnosis, treatments, and preventative measures.
              </p>
              <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                <Link
                  className="btn btn-success btn-lg px-4 me-sm-3"
                  to="/login"
                >
                  Start Consulting
                </Link>
                <Link
                  className="btn btn-outline-light btn-lg px-4"
                  to="/about"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>

         {/*  <div className="col-lg-4 col-xl-7 col-xxl-6">

          <div id="robotcarousel" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button
      type="button"
      data-bs-target="#robotcarousel"
      data-bs-slide-to={0}
      className="active"
      aria-current="true"
      aria-label="Slide 1"
    />
    <button
      type="button"
      data-bs-target="#robotcarousel"
      data-bs-slide-to={1}
      aria-label="Slide 2"
    />
  </div>
  <div className="carousel-inner">
    <div className="carousel-item">
      <img
        className="d-block w-100 rounded-3"
        src="/sample.png"
        alt="swamp"
      />
    </div>
    <div className="carousel-item active">
      <img
        className="d-block w-100"
        src="https://pixelprowess.com/i/carousel_flight.png"
        alt="flight"
      />
    </div>
  </div>
  <button
    className="carousel-control-prev"
    type="button"
    data-bs-target="#robotcarousel"
    data-bs-slide="prev"
  >
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span>
  </button>
  <button
    className="carousel-control-next"
    type="button"
    data-bs-target="#robotcarousel"
    data-bs-slide="next"
  >
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span>
  </button>
</div>
          </div> */}

           <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center">
        <img
          className="img-fluid rounded-3 my-5 w-85"
          src="/sample.png"
          alt="..."
          width = "550" height = "550"
        />
      </div>  
        </div>
      </div>
    </header>
  );
};

export default Hero;
