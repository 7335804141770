import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'


const ChatHeader = (props) => {

  const navigate = useNavigate();

  const handleHomeBtnClick = () => {
    navigate('/');
  };

  const handleClearBtnClick = () => {
    props.onClearBtnClick();
  };

  return (
    <div className="card-header d-flex justify-content-between align-items-center p-3">
      <h5 className="mb-0">Dr. Chatgpt</h5>

      <div className="d-flex justify-content-between align-items-center">
        <button
          type="button"
          className="btn btn-success btn-sm me-2"
          data-mdb-ripple-color="dark"
          onClick={handleHomeBtnClick}
        >
          Back to Home
        </button>

        <button
          type="button"
          className="btn btn-secondary btn-sm"
          data-mdb-ripple-color="dark"
          onClick={handleClearBtnClick}
        >
          Clear Chat
        </button>
      </div>
    </div>
  );
};

export default ChatHeader;
