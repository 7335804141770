import React, { useState, useEffect } from "react";


const ChatMessageGpt = ({ gptcontenttyped, message }) => {
 
  const [typedMessage, setTypedMessage] = useState("");
 
  useEffect(() => {    

    //if msg is empty, then return
    if (message.content === "") {
      return;
    }

    let i = 0;
    const typing = setInterval(() => {
      setTypedMessage((prev) => prev + message.content[i++]);      
      
      if (i === message.content.length-1) {
        clearInterval(typing);
        gptcontenttyped();
      }
       
    }, 30);
    return () => {
      clearInterval(typing);
    };
  }, [message.content]);

  return (
    <div className="d-flex flex-row justify-content-start">
      <img
        src="/doctor.png"
        alt="avatar 1"
        style={{ width: 45, height: "100%" }}
      />
      <div>
        <p
          className="small p-2 ms-3 mb-1 rounded-3"
          style={{ backgroundColor: "#f5f6f7" }}
        >
          {typedMessage}
          
        </p>
         
        {/* <p className="small ms-3 mb-3 rounded-3 text-muted">23:58</p> */}
      </div>
    </div>
  );
};

export default ChatMessageGpt;
