import React, { useRef, useEffect } from "react";

import ChatMessageGpt from "./ChatMessageGpt";
import ChatMessageUser from "./ChatMessageUser";

const ChatBody = ({chatLog}) => {

  const chatContainerRef = useRef(null);
 
  console.log(chatLog);

  useEffect(() => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [chatLog]);

  function gptcontenttyped() {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }

  return (
    <div
      className="card-body"
      ref={chatContainerRef}
       
      style={{
        position: "relative",
        height: "calc(100vh - 325px)",
        overflowY: "scroll",
      }}
    > 

 
      {chatLog.map((message, index) => {
        if (message.role === "assistant") {
          return <ChatMessageGpt gptcontenttyped={gptcontenttyped} key={index} message={message} />;
        } else {
          return <ChatMessageUser key={index} message={message} />;
        }
      })}
      

    </div>
  );
};

export default ChatBody;
