import React from 'react'

const Contact = () => {
  return (
    <section className="py-5">
  <div className="container px-3"
  
  style={{
    position: "relative",
    height: "calc(100vh - 200px - 60px)" 
     
  }}
  >
    
    <div className="bg-light rounded-3 py-3 px-4 px-md-5 mb-5">
      <div className="text-center justify-content-center mb-5">
        
      <div className="card border-0 bg-light mt-xl-5">
          <div className="card-body p-4 py-lg-5">
            <div className="d-flex align-items-center justify-content-center">
              <div className="text-center">
                <div className="h6 fw-bolder">Have more questions?</div>
                <p className="text-muted mb-4">
                  Contact me at
                  <br />
                  <a href="mailto:antonycjohnson@gmail.com">antonycjohnson@gmail.com</a>
                </p>
                <div className="h6 fw-bolder">Follow me</div>
                <a className="fs-5 px-2 link-dark" target="_blank" href="https://twitter.com/speak2AI">
                      <i className="bi-twitter" />
                    </a>
                    <a className="fs-5 px-2 link-dark" target="_blank" href="https://www.facebook.com/antonycjohnson">
                      <i className="bi-facebook" />
                    </a>
                    <a className="fs-5 px-2 link-dark" target="_blank" href="https://www.linkedin.com/in/antjohns">
                      <i className="bi-linkedin" />
                    </a>
                   {/*  <a className="fs-5 px-2 link-dark" href="#!">
                      <i className="bi-youtube" />
                    </a> */}
              </div>
            </div>
          </div>
        </div>
        
      </div>

      
      
    </div>
    {/* Contact cards*/}
     
    
  </div>
</section>

  )
}

export default Contact