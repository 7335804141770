import React from 'react'
import { Link } from 'react-router-dom'


const Footer = () => {
  return (
    <footer className="footer bg-dark py-4 mt-auto">
  <div className="container px-5">
    <div className="row align-items-center justify-content-between flex-column flex-sm-row">
      <div className="col-auto">
        <div className="small m-0 text-white">
          Copyright © DrChatgpt.org 2023
        </div>
      </div>
      <div className="col-auto">
        {/* <a className="link-light small" href="#!">
          Privacy
        </a> */}
        <span className="text-white mx-1"></span>
        <Link className="link-light small" to="/terms">
          Terms
        </Link>
        <span className="text-white mx-1">·</span>
        <Link className="link-light small" to="/Contact">
          Contact
        </Link>
      </div>
    </div>
  </div>
</footer>

  )
}

export default Footer