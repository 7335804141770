import React from "react";
import ChatBox from "../components/chat/ChatBox";
import { useParams } from "react-router-dom";

const Chat = () => {
  
  let { language } = useParams();

  //If language is missing then defualt to english
  if (language === undefined) {
    language = "en";
  }

  return <ChatBox language={language} />;
};

export default Chat;
