import React from 'react'

const Quote = () => {
  return (
    <div className="py-1 bg-light">
  <div className="container px-5 my-5">
    <div className="row gx-5 justify-content-center">
      <div className="col-lg-10 col-xl-7">
        <div className="text-center">
          <div className="fs-4 mb-2 lead">
            "Not all of us can do great things. But we can do small things with
            great love." - Mother Teresa
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Quote