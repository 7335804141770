import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [selectedLang, setselectedLang] = useState("en");

  function handleDropdownChange(e) {
    setselectedLang(e.target.value);
    //const value = e.target.value;
    setAcceptedTerms(false);
    //navigate("/chat/" + value);
  }

  const handleSubmit = () => {
    navigate("/chat/" + selectedLang);
  };

  return (
    <section className="py-5">
      <div
        className="container px-3"
        
      >
        <div className="bg-light rounded-3 py-5 px-4 px-md-5 mb-5">
          <div className="text-center mb-5">
            <h1 className="fw-bolder">Select your language</h1>
            <p className="lead fw-normal text-muted mb-0">
              You can choose from more than 100 languages!
            </p>
          </div>
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8 col-xl-6">
              <form id="contactForm" data-sb-form-api-token="API_TOKEN">
                <div className="form-group mb-5 mt-2">
                  <label htmlFor="languageSelect"></label>
                  <select
                    className="form-control mt-2"
                    id="languageSelect"
                    data-sb-validations="required"
                    onChange={handleDropdownChange}
                  >
                    <option value="">Select a language</option>

                    <option value="en" selected>
                      English
                    </option>
                    <option value="de">German</option>
                    <option value="fr">French</option>
                    <option value="es">Spanish</option>
                    <option value="it">Italian</option>
                    <option value="pt">Portuguese</option>
                    <option value="zh">Chinese</option>
                    <option value="es">Spanish</option>
                    <option value="hi">Hindi</option>
                    <option value="ar">Arabic</option>
                    <option value="bn">Bengali</option>
                    <option value="pt">Portuguese</option>
                    <option value="ru">Russian</option>
                    <option value="ja">Japanese</option>
                    <option value="pa">Punjabi</option>
                    <option value="ms">Malay</option>
                    <option value="fr">French</option>
                    <option value="te">Telugu</option>
                    <option value="vi">Vietnamese</option>
                    <option value="ko">Korean</option>
                    <option value="mr">Marathi</option>
                    <option value="ta">Tamil</option>
                    <option value="ml">Malayalam</option>
                    <option value="tr">Turkish</option>

                   
                  

                    <option value="af">Afrikaans</option>
                    <option value="sq">Albanian</option>
                    <option value="am">Amharic</option>
                    <option value="ar">Arabic</option>
                    <option value="hy">Armenian</option>
                    <option value="as">Assamese*</option>
                    <option value="ay">Aymara*</option>
                    <option value="az">Azerbaijani</option>
                    <option value="bm">Bambara*</option>
                    <option value="eu">Basque</option>
                    <option value="be">Belarusian</option>
                    <option value="bn">Bengali</option>
                    <option value="bho">Bhojpuri*</option>
                    <option value="bs">Bosnian</option>
                    <option value="bg">Bulgarian</option>
                    <option value="ca">Catalan</option>
                    <option value="ceb">Cebuano</option>
                    <option value="zh">Chinese (Simplified)</option>
                    <option value="zh-TW">Chinese (Traditional)</option>
                    <option value="co">Corsican</option>
                    <option value="hr">Croatian</option>
                    <option value="cs">Czech</option>
                    <option value="da">Danish</option>
                    <option value="dv">Dhivehi*</option>
                    <option value="doi">Dogri*</option>
                    <option value="nl">Dutch</option>
                    <option value="eo">Esperanto</option>
                    <option value="et">Estonian</option>
                    <option value="ee">Ewe*</option>
                    <option value="fil">Filipino (Tagalog)</option>
                    <option value="fi">Finnish</option>
                    <option value="fr">French</option>
                    <option value="fy">Frisian</option>
                    <option value="gl">Galician</option>
                    <option value="ka">Georgian</option>
                    <option value="de">German</option>
                    <option value="el">Greek</option>
                    <option value="gn">Guarani*</option>
                    <option value="gu">Gujarati</option>
                    <option value="ht">Haitian Creole</option>
                    <option value="ha">Hausa</option>
                    <option value="haw">Hawaiian</option>
                    <option value="he">Hebrew</option>
                    <option value="iw">Hebrew</option>
                    <option value="hi">Hindi</option>
                    <option value="hmn">Hmong</option>
                    <option value="hu">Hungarian</option>
                    <option value="is">Icelandic</option>
                    <option value="ig">Igbo</option>
                    <option value="ilo">Ilocano*</option>
                    <option value="id">Indonesian</option>
                    <option value="ga">Irish</option>
                    <option value="it">Italian</option>
                    <option value="ja">Japanese</option>
                    <option value="jv">Javanese</option>
                    <option value="jw">Javanese</option>
                    <option value="kn">Kannada</option>
                    <option value="kk">Kazakh</option>
                    <option value="km">Khmer</option>
                    <option value="rw">Kinyarwanda</option>
                    <option value="gom">Konkani*</option>
                    <option value="ko">Korean</option>
                    <option value="kri">Krio*</option>
                    <option value="ku">Kurdish</option>
                    <option value="ckb">Kurdish (Sorani)*</option>
                    <option value="ky">Kyrgyz</option>
                    <option value="lo">Lao</option>
                    <option value="la">Latin</option>
                    <option value="lv">Latvian</option>
                    <option value="ln">Lingala*</option>
                    <option value="lt">Lithuanian</option>
                    <option value="lg">Luganda*</option>
                    <option value="lb">Luxembourgish</option>
                    <option value="mk">Macedonian</option>
                    <option value="mai">Maithili*</option>
                    <option value="mg">Malagasy</option>
                    <option value="ms">Malay</option>
                    <option value="ml">Malayalam</option>
                    <option value="mt">Maltese</option>
                    <option value="mi">Maori</option>
                    <option value="mr">Marathi</option>
                    <option value="mni-Mtei">Meiteilon (Manipuri)*</option>
                    <option value="lus">Mizo*</option>
                    <option value="mn">Mongolian</option>
                    <option value="my">Myanmar (Burmese)</option>
                    <option value="ne">Nepali</option>
                    <option value="no">Norwegian</option>
                    <option value="ny">Nyanja (Chichewa)</option>
                    <option value="or">Odia (Oriya)</option>
                    <option value="om">Oromo*</option>
                    <option value="ps">Pashto</option>
                    <option value="fa">Persian</option>
                    <option value="pl">Polish</option>
                    <option value="pt">Portuguese (Portugal, Brazil)</option>
                    <option value="pa">Punjabi</option>
                    <option value="qu">Quechua*</option>
                    <option value="ro">Romanian</option>
                    <option value="ru">Russian</option>
                    <option value="sm">Samoan</option>
                    <option value="sa">Sanskrit*</option>
                    <option value="gd">Scots Gaelic</option>
                    <option value="nso">Sepedi*</option>
                    <option value="sr">Serbian</option>
                    <option value="st">Sesotho</option>
                    <option value="sn">Shona</option>
                    <option value="sd">Sindhi</option>
                    <option value="si">Sinhala (Sinhalese)</option>
                    <option value="sk">Slovak</option>
                    <option value="sl">Slovenian</option>
                    <option value="so">Somali</option>
                    <option value="es">Spanish</option>
                    <option value="su">Sundanese</option>
                    <option value="sw">Swahili</option>
                    <option value="sv">Swedish</option>
                    <option value="tl">Tagalog (Filipino)</option>
                    <option value="tg">Tajik</option>
                    <option value="ta">Tamil</option>
                    <option value="tt">Tatar</option>
                    <option value="te">Telugu</option>
                    <option value="th">Thai</option>
                    <option value="ti">Tigrinya*</option>
                    <option value="ts">Tsonga*</option>
                    <option value="tr">Turkish</option>
                    <option value="tk">Turkmen</option>
                    <option value="ak">Twi (Akan)*</option>
                    <option value="uk">Ukrainian</option>
                    <option value="ur">Urdu</option>
                    <option value="ug">Uyghur</option>
                    <option value="uz">Uzbek</option>
                    <option value="vi">Vietnamese</option>
                    <option value="cy">Welsh</option>
                    <option value="xh">Xhosa</option>
                    <option value="yi">Yiddish</option>
                    <option value="yo">Yoruba</option>
                    <option value="zu">Zulu</option>
                  </select>
                </div>

                <div className="form-group mb-5 mt-2">
                  <input
                    type="checkbox"
                    id="acceptTermsCheckbox"
                    onChange={() => setAcceptedTerms(!acceptedTerms)}
                    style={{ verticalAlign: "middle", display: "inline-block" }}
                    checked={acceptedTerms}
                  />
                  
                  {" "} I understand that this chatbot is purely informative and not a replacement for professional physician advice. I have read and agree to the {" "}
                    <a href="/terms">terms</a>.
                   
                </div>

                <div className="d-grid">
                  <button
                    className="btn btn-success btn-lg"
                    id="submitButton"
                    type="submit"
                    disabled={!acceptedTerms}
                    onClick={() => handleSubmit()}
                  >
                    Start Chat..
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Contact cards*/}
        <div style={{ height: 230}} />

      </div>
    </section>
  );
};

export default Login;
