import React from "react";

const Terms = () => {
  return (
    <section className="py-3">
      <div className="container px-5 my-5">
        <div className="row gx-5">
          <div className="col-xl-8">
            {/* FAQ Accordion 1*/}
            <h2 className="fw-bolder mb-3">Terms & Conditions:</h2>
            <div className="accordion mb-5" id="accordionExample">
              <>
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      Please read it carefully:
                    </button>
                  </h3>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      We're glad you've taken the time to read our terms and
                      conditions! By using our website, you agree to these terms
                      and conditions in their entirety. If you do not agree to
                      these terms, please do not use Dr.ChatGpt bot.
                      <p></p>
                      Our website, Dr.ChatGpt, is designed to provide users with
                      medical information and advice in over 100 languages.
                      While we strive to provide accurate and up-to-date
                      information, we cannot guarantee the accuracy,
                      completeness, or reliability of the information provided
                      by our AI chatbot. The information which the bot provides is not from ours and 
                      it is obtained from the GPT-3 OpenAI api.
                      <p></p>
                      We want to emphasize that the information provided by
                      Dr.ChatGpt is purely informational and should not be
                      considered equivalent to the advice of a licensed
                      physician. The website owner and developers are not
                      responsible for any consequences that may result from
                      following the advice of the AI chatbot.
                      <p></p>
                      Our AI chatbot is designed to provide medical advice and
                      information only. Users are strictly prohibited from using
                      our service for anything other than medical related
                      queries. Even though our AI bot will avoid such questions,
                      users are not allowed to engage in conversations that are
                      harmful to human society or involve topics related to
                      pornography, child abuse, or any other illegal activity.
                      <p></p>
                      We strongly advise users to use their own knowledge and
                      judgment when using our site, and to consult with a
                      qualified healthcare provider for any medical concerns or
                      questions. Our goal is to provide a helpful resource for
                      medical information, but ultimately, your health and
                      safety are your responsibility.
                      <p></p>
                      Thank you for taking the time to read our terms and
                      conditions. If you have any questions or concerns, please
                      feel free to contact us through our Contact page.
                    </div>
                  </div>
                </div>
              </>
            </div>
            {/* FAQ Accordion 2*/}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Terms;
