import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <header className="py-2">
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-lg-8 ">
              <div className="my-3">
                <h1 className="fw-bolder mb-4">About Dr. Chatgpt</h1>
                <p></p>
                Dr. ChatGpt is an AI Medical chatbot designed to provide
                clear and concise answers to medical related
                questions in over 100 languages.                 
                Dr.ChatGpt, is powered by advanced technology from OpenAI's{" "}
                <a target="_blank" href="https://openai.com/blog/gpt-3-apps/">
                  GPT-3 Language model{" "}
                </a>{" "}
                enabling it to answer a wide range of health-related queries. We
                use Google's translation API to support over 100 languages,
                making it easier for people all over the world to get the
                medical advice they need in their own language.  
                <p></p>
                <p></p>
                Dr. ChatGpt is currently in{" "}
                <strong> beta mode </strong>, and we welcome feedback from our
                users as we work to improve, bug fix and expand our services. We
                are also seeking sponsorships from organizations that share our
                mission, so that we can continue to provide this
                service for years to come at free of cost.
                <p></p>
                Please visit our Contact page to get
                in touch with us, and remember to use Dr.ChatGpt as a helpful
                supplement to, not a replacement for, the advice of a licensed
                healthcare professional.
                <p></p>
                <strong>How can I ask questions better to Dr.ChatGpt?</strong>
                <p></p>
                Ask questions more naturally like how you ask to your doctor. Do
                not worry about grammatically correct sentences. Dr.ChatGpt will
                understand your questions.
                <p></p>
                Keep your question elaborate and specific.
                <p></p>
                <b>Example:</b>
                <p></p>
                1. I have a headache and I am feeling dizzy. What should I do?
                <p></p>
                2. I am 35 years old. I feel shoulder pain each day at end of
                the day. I am not able to sleep properly. I am feeling very
                tired. What should I do?
                <p></p>
                3. Can you suggest some home remedies for cough?
                <p></p>
                Also remember that Dr.ChatGpt knows the context of your previous
                questions. So, you can ask follow-up questions without repeating
                the previous questions.
                <p></p>
                <strong>Limitations:</strong>
                <p></p>
                It's important to note that the advice provided by Dr.ChatGpt is
                purely informational and should not be considered equivalent to
                the advice of a licensed physician. We strongly advise users to
                use their own knowledge and judgment when using our site, and to
                consult with a qualified healthcare provider for any medical
                concerns or questions.
                <p></p>
                <br />
                
                <br />
                <strong>Antony Johnson Cruz </strong>  
                <br />
                Founder, www.drchatgpt.org
                <p></p>
                <div className="">
                 
                <p className="text-muted mb-4">
                  
                  <a href="mailto:antonycjohnson@gmail.com">antonycjohnson@gmail.com</a>
                </p>
                <div className="h6 fw-bolder">Follow me</div>
                <a className="fs-5 px-2 link-dark" target="_blank" href="https://twitter.com/speak2AI">
                      <i className="bi-twitter" />
                    </a>
                    <a className="fs-5 px-2 link-dark" target="_blank" href="https://www.facebook.com/antonycjohnson">
                      <i className="bi-facebook" />
                    </a>
                    <a className="fs-5 px-2 link-dark" target="_blank" href="https://www.linkedin.com/in/antjohns">
                      <i className="bi-linkedin" />
                    </a>
                   {/*  <a className="fs-5 px-2 link-dark" href="#!">
                      <i className="bi-youtube" />
                    </a> */}
              </div>
                <p></p>
                {/* <div className="text-left">
                  <img
                    className="img-fluid square mb-4 px-4 w-25"
                    src="https://media.licdn.com/dms/image/C4E03AQHX7qEOL3ypIA/profile-displayphoto-shrink_800_800/0/1521932995835?e=1681344000&v=beta&t=Md7KhpNDW4qhND1kUuMlMki6wbL73OrjbtNkw8J9eFE"
                    alt="..."
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* Team members section*/}
    </>
  );
};

export default About;
