import React from "react";
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

const ChatFooter = (props) => {


  const navigate = useNavigate();
  const userInputRef = useRef();

  const styles = {
    bordercolor: "transparent",
    border: "none",
    boxshadow: "inset 0px 0px 0px 1px transparent",
  };


  const submitHandler = (e) => {
    e.preventDefault();
    props.onAddChatInput(userInputRef.current.value);
    userInputRef.current.value = "";
  };

  return (
    <div className="card-footer text-muted d-flex justify-content-start align-items-center p-3">
      <textarea         
        rows="3"        
        style={{
          bordercolor: "transparent",
          border: "none",
          boxshadow: "inset 0px 0px 0px 1px",
        }}
        className="form-control input-sm"
        id="exampleFormControlInput1"
        placeholder={props.promptMsg}
        ref={userInputRef}
       //onChange={
        //(e) => setChatInput(e.target.value)
        //}
      />
      <form>
        <a href="#!" className="ms-3" onClick={submitHandler}>
          <i className="fas fa-paper-plane fa-2x text-success"></i>
        </a>

      </form>
    </div>
  );
};

export default ChatFooter;
