import React from "react";
import { useLocation } from "react-router-dom";

import MainNavigation from "./MainNavigation";
import Footer from "./Footer";

const Layout = (props) => {

  const location = useLocation();
 
   // Check if the current location pathname starts with "/chat"
   const hideFooter = location.pathname.startsWith('/chat');
   const hideNavigation = location.pathname.startsWith('/chat');

  return (

    <main>
      {hideNavigation ? null : <MainNavigation />}
      {props.children}
      {hideFooter ? null : <Footer />}
    </main>
  );
};

export default Layout;
