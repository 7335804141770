import React from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

const MainNavigation = () => {
  function handleNavClick() {
    //document.querySelector('.navbar-collapse').classList.remove('show');
    const navbar = document.querySelector(".navbar-collapse");
    navbar.classList.remove('show');
    navbar.style.height = navbar.scrollHeight + 'px';
    setTimeout(() => {
      navbar.style.height = '';
    }, 5000);
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container my-3 px-5">
        <span>
          <i
            className="bi-code-square me-3"
            style={{ fontSize: "2.5rem", color: "#198754" }}
          />
        </span>
        <Link className="navbar-brand fw-bolder" to="/">
          {" "}
          Dr. Chatgpt  <span className="lead" style={{ color: "grey" }} >(Beta)</span>          
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className="nav-link"
                onClick={() => handleNavClick()}
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                onClick={() => handleNavClick()}
                to="/login"
              >
                Chat
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                onClick={() => handleNavClick()}
                to="/about"
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                onClick={() => handleNavClick()}
                to="/faq"
              >
                FAQ
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                onClick={() => handleNavClick()}
                to="/contact"
              >
                Contact
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                onClick={() => handleNavClick()}
                to="/donate"
              >
                Donate{" "}
                <i className="bi bi-heart-fill" style={{ color: "white" }} />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MainNavigation;
